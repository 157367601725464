import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageR = () => {
  // 設定項目 ================================================================================================
  const myLine = 'ら行'
  const myID = 'ra_'
  const pathName = 'term-ra';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'利食い',
    Content:'利食いとは保持するポジションの利益を確定する決済取引のことです。'
  },
  {
    Title:'両建て',
    Content:'同一通貨ペアで買いポジションと売りポジションの両方のポジションを同時に持つことです。'
  },
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageR